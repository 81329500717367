
  <template>
    <div class="box">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
        <table class="detailTable_search" style="width: 100%">
          <tr>
            <td class="titleTd width80px">日期</td>
          <td class="valueTd">
            <div class="block">
              <el-date-picker
                v-model="dataForm.time"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeHandler"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </td>
          <td class="titleTd width80px">区域</td>
              <td class="valueTd">
                <region-tree-jurisdiction :rootId="gridId !== '' ? gridId : regionId" placeholder="请选择区域" v-model="dataForm.regionId" @typeChange="typeChange"> </region-tree-jurisdiction>
          </td>
          <td class="titleTd width80px">用户状态</td>
          <td>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <!-- <el-checkbox-group v-model="checkedList" @change="handlecheckedListChange"> -->
            <el-checkbox v-model="checkedList" @change="handlecheckedListChange" v-for="item in list" :label="item.value" :key="item.id">{{item.name}}</el-checkbox>
            <!-- </el-checkbox-group> -->
          </td>
          </tr>
        </table>
      </el-form>

      <div class="wxts_msg_search" >
        <span style="margin-left: 15px">截止统计时间:{{jzrq}}</span>
        <div class="f_t">
          <el-button-group  >
            <el-button size="mini" type="success" @click="search(dataForm.type)" :disabled="dataListLoading">查询</el-button>
            <el-button size="mini" type="warning" @click="exportData()">{{ $t('export') }}</el-button>
            <el-button size="mini" type="primary" @click="goBack()" icon="el-icon-back" v-if="orgIdList.length>0" :disabled="dataListLoading">返回</el-button>
          </el-button-group>
        </div>
      </div>
      <div class="">
        <div v-loading="dataListLoading"  id="histogram" class="histogramClass"></div>
      <el-table
              :data="dataList"
              style="width: 100%"
              :summary-method="getSummaries" show-summary>
        {{dataList}}
        <el-table-column
                    label="区域"
                    align="center"
                    style="color:#f5f7fa">
                <template slot-scope="scope">
                    <a style="color: blue;cursor: pointer" @click="area(scope.row.areaCode)"
                       v-if="scope.row.type!=4">{{scope.row.areaName}}</a>
                    <span v-else>{{scope.row.areaName}}</span>
                </template>
            </el-table-column >
          <el-table-column
                  prop="oneWay"
                  label="单向"
                  align="center"
          >
            <template slot-scope="scope" >
              <span>{{scope.row.oneWay}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="bwg"
                  label="单宽带"
                  align="center"
          >
            <template slot-scope="scope" >
              <span >{{scope.row.bwg}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="ott"
                  label="ott"
                  align="center"
          >
            <template slot-scope="scope" >
              <span>{{scope.row.ott}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="vod"
                  label="vod"
                  align="center"
          >
            <template slot-scope="scope" >
              <span>{{scope.row.vod}}</span>
            </template>
          </el-table-column>
          <el-table-column
                  prop="fiveG"
                  label="5G"
                  align="center"
          >
            <template slot-scope="scope" >
              <span>{{scope.row.fiveG}}</span>
            </template>
          </el-table-column>
      </el-table>
    </div>
      <!-- <div class="button">
        <el-row>
          <el-button type="success" round :model="dataForm.bType" @click="exchange1()">正常使用</el-button>
          <el-button type="info" round :model="dataForm.bType" @click="exchange2()">报&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;停</el-button>
        </el-row>
      </div>  -->
    </div>
  </template>


  <script>
  import {twoOnlineCount} from "@/api/kanban/secondLevel";
  const listOptions = [{id:1,name:'正常使用',value:'01'},{id:2,name:'停用',value:'02'}];
  export default {
    data(){
      return{
        dataListLoading: false,
        dataList:[],
        orgIdList: [],
        jzrq:'',
        checkAll: false,
        checkedList: ['01'],
        list: listOptions,
        isIndeterminate: true,
        dataForm:{
          userState:'01',
          startTime:'',
          endTime:'',
          regionId:'',
          type:'',
          time:[]
        },
        pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      }
    },

    components: {

    },

    activated() {
      this.getNowDate();
    },

    created(){
    },

    mounted() {
        this.poTwoOnlineCount();
    },


    computed: {
    regionId: {
            get() {
                const newregionId = this.$store.state.user.regionId;
                if (newregionId !== undefined) {
                    return newregionId;
                } else {
                    return ''
                }
            }
        },
        gridId: {
            get() {
                const newgridId = this.$store.state.user.gridId;
                if (newgridId !== undefined) {
                    return newgridId;
                } else {
                    return ''
                }
            }
        },
    },
    methods: {
      changeHandler(val){
      this.dataForm.time = val;
      if(this.dataForm.time != null){
        this.dataForm.startTime = this.dealdate(this.dataForm.time[0]);
        this.dataForm.endTime = this.dealdate(this.dataForm.time[1]);
      }else{
        this.dataForm.startTime = '';
        this.dataForm.endTime = '';
      }
    },
      search(type){
        if(type==='4'){
          this.$message({
            message: '该区域无下属网格，不能查询！',
            type: 'warning'
          })
        }else{
          this.poTwoOnlineCount();
        }

      },
      typeChange(data){
        this.dataForm.type = data.type
      },
      poTwoOnlineCount(areaCode) {
      let userStates = ''
      const _this = this;
      if(null != _this.checkedList && _this.checkedList.length>0){
        userStates = _this.checkedList.join(',')
      }
      if(areaCode===undefined){
      twoOnlineCount(
        {
          userState:userStates,
          startTime: _this.dataForm.startTime,
          endTime: _this.dataForm.endTime,
          areaCode:_this.dataForm.regionId ? _this.dataForm.regionId : (_this.$store.state.user.gridId !== undefined ? _this.$store.state.user.gridId : _this.$store.state.user.regionId)
        }
      ).then(({data}) => {
        if (data && data.data && data.code === 0) {
          _this.dataList = data.data;
          const newList = []
          _this.dataList.forEach(element => {
            newList.push(element.areaName)
          });
          _this.onlineHistogram(newList,_this.dataList)
        }
      })}else{
        twoOnlineCount(
        {
          userState:userStates,
          startTime: _this.dataForm.startTime,
          endTime: _this.dataForm.endTime,
          areaCode:areaCode
        }
      ).then(({data}) => {
        if (data && data.data && data.code === 0) {
          _this.dataList = data.data;
          const newList = []
          _this.dataList.forEach(element => {
            newList.push(element.areaName)
          });
          _this.onlineHistogram(newList,_this.dataList)
        }
      })
      }
    },
    onlineHistogram(newList,datas){
        var myChart = this.$echarts.init(document.getElementById('histogram'));
        var option;
      option = {
        //滚动条
        dataZoom: {
          show: true,
          // realtime: true,
          height: 5,
          bottom: 10,
          start: 0,
          end: 50,
          showDetail:false
        },
        legend: {
          data: ['单向', '单宽带', 'ott','vod','5G'],
          orient: 'horizontal',
          y: 'top',
          x: 'center',
          // itemGap: 40
        },
        tooltip: {
          trigger: 'axis',
          axisPointer : {            // 坐标轴指示器，坐标轴触发有效
            type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (params) => {
            return params[0].name + '<br>' +
                    params[0].marker + ' ' + params[0].seriesName + ': ' + params[0].data + ' 个' + '<br>'+
                    params[1].marker + ' ' + params[1].seriesName + ': ' + params[1].data + ' 个' + '<br>'+
                    params[2].marker + ' ' + params[2].seriesName + ': ' + params[2].data + ' 个' + '<br>'+
                    params[3].marker + ' ' + params[3].seriesName + ': ' + params[3].data + ' 个' + '<br>'+
                    params[4].marker + ' ' + params[4].seriesName + ': ' + params[4].data + ' 个' + '<br>'

          },
        },
        grid: {
          bottom: '10',
          top: "100",
          containLabel: true
        },
        xAxis : {
          type : 'category',
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,//使x轴文字显示全
            rotate: 40,
            lineHeight: 18,
            formatter: function(params) {
              var newParamsName = ''// 最终拼接成的字符串
              var paramsNameNumber = params.length// 实际标签的个数
              var provideNumber = 10// 每行能显示的字的个数
              // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
              if (paramsNameNumber > provideNumber) {
                // ********重点在这里********
                newParamsName = params.substring(0, 10) + '..'// 最终拼成的字符串
              } else { // 将旧标签的值赋给新标签
                newParamsName = params
              }
              // 将最终的字符串返回
              return newParamsName
            }
          },
          data : newList
        } ,
        yAxis: {

        },
        series : [ {
          name : '单向',
          type : 'bar',
          barWidth: 15,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.oneWay);
            })
            return data;
          })()
        }, {
          name : '单宽带',
          type : 'bar',
          barWidth: 15,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.bwg);
            });
            return data;
          })()
        }, {
          name : 'ott',
          type : 'bar',
          barWidth: 15,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.ott);
            });
            return data;
          })()
        },{
          name : 'vod',
          type : 'bar',
          barWidth: 15,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.vod);
            });
            return data;
          })()
        },{
          name : '5G',
          type : 'bar',
          barWidth: 15,//柱图宽度
          // label: {
          //   normal: {
          //     show: true,
          //     position: 'top',
          //     textStyle: {
          //       color: 'black'
          //     }
          //   }
          // },
          data : (function() {
            var data = [];
            datas.forEach(function(item) {
              data.push(item.fiveG);
            });
            return data;
          })()
        }]

      };
    option && myChart.setOption(option);
    window.addEventListener("resize", () => {
        myChart.resize();
      })
    },
    getObjectValues(object){
      var values = [];
      for (var property in object)
      values.push(object[property]);
      return values;
    },
    exchange1(){
      this.dataForm.userState='';
      this.dataForm.userState='01';
      this.poTwoOnlineCount();
    },
    exchange2(){
      this.dataForm.userState='';
      this.dataForm.userState='02';
      this.poTwoOnlineCount();
    },
        //时间格式化函数，此处仅针对yyyy-MM-dd hh 的格式进行格式化
      dateFormat(time) {
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hours = date.getHours() < 10 ? '0' + date.getHours()-1 : date.getHours()-1
      // var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // 拼接
      return year + '-' + month + '-' + day + ' ' + hours
    },
    // 日期转换
    dealdate(date) {
      const dt = new Date(date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      return `${y}-${m}-${d}`
    },
    /**
     * 查询当天日期
     */
     getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      this.jzrq = NOW_MONTHS_AGO
      return NOW_MONTHS_AGO
    },
    // 地区钻取
    area (areacode) {
      if(this.dataForm.regionId == ''){
        this.orgIdList.push(this.regionId)
      }else{
        this.orgIdList.push(this.dataForm.regionId)
      }
      this.dataForm.regionId=areacode
     this.poTwoOnlineCount(areacode)
    },
    goBack () { // 回退
        this.dataForm.regionId = this.orgIdList[this.orgIdList.length - 1]
        this.poTwoOnlineCount(this.dataForm.regionId);
        this.orgIdList.splice(this.orgIdList.length - 1, 1)
      },
    handleCheckAllChange(val) {
        this.checkedList =  [];
        if(val){
          this.checkedList = ['01','02']
        }
        this.isIndeterminate = false;
      },
    handlecheckedListChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.list.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;
      },
          // 求和操作
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计:'
        }
        if (index === 8) {
          sums[index] = '总记录数:' + this.total
        }
          // index >= 5 || index <= 7 是你需要计算的列
          // 当然也可以指定一个或者多个列
        // index === 2 || index === 3 这样值求和第二个与第三个列

        else if (index >= 1 && index <= 6) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                // 保存了两位小数点
                return Math.floor((prev + curr) * 100) / 100;
              } else {
                // 保存了两位小数点
                return Math.floor(prev * 100) / 100;
              }
            }, 0)
            sums[index];
          } else {
            sums[index] = 'N/A'
          }
        } else {
          // sums[index] = ''
        }
      })
      return sums
    },
    exportData(){
      const token = this.$cookie.get('x-tr-mimosa-token') // this.$cookie.get('token')
      // 导出数据
      let userStates = ''
      if(null != this.checkedList && this.checkedList.length>0){
        userStates = this.checkedList.join(',')
      }
      const time = new Date().getTime()
      const url = this.$http.adornUrl(`/dataflow/olfonlineuser/export?` +
        'token=' + token +
        '&time=' + time +
        '&startTime='+ this.dataForm.startTime+
        '&endTime='+ this.dataForm.endTime+
        '&userState='+ userStates+
        '&areaCode='+ (this.dataForm.regionId ? this.dataForm.regionId : (this.gridId !== '' ? this.gridId : this.regionId))
      )
      window.location.href = url
    },
    }
  }
  </script>
<style scoped>
  .box{
    width: 100%;
    height:100%;
  }
  .histogramClass{
    margin-top: 20px;
    width: 100%;
    height: 350px;
  }
  .stateClass{
    width:100%;
    height:90%;
    display: flex;
    align-items: center;
  }
  .b-sousuo{
    display: flex;
  }
</style>
